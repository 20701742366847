import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(({ spacing, palette, breakpoints }) => ({
  container: {
    backgroundColor: "lightblue",
    height: "100vh",
    width: "100%",
  },
  heading: {
    [breakpoints.up("md")]: {
      fontSize: "200px",
    },
    fontSize: "100px",
    color: "white",
    textAlign: "center",
    fontFamily: "Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
  },
  sub: {
    [breakpoints.up("md")]: {
      fontSize: "40px",
    },
    fontSize: "20px",
    color: "white",
    textAlign: "center",
    fontFamily: "Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
  },
  innerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  images: {
    marginTop: "100px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  linkedIn: {
    cursor: "pointer",
  },
  email: {
    cursor: "pointer",
    paddingLeft: "20px",
  },
  link: {
    textDecoration: "none",
  },
}));

export default useStyles;
