import React, { useEffect, useState } from "react";
import useStyles from "./App.styles";
import LinkedInImage from "./assets/images/linkedin.png";
import EmailImage from "./assets/images/email.png";

function App() {
  const [numberOfRuns, setNumberOfRuns] = useState(0);
  const styles = useStyles();

  useEffect(() => {
    animateColor();
  }, []);

  useEffect(() => {
    setTimeout(animateColor, 750);
  }, [numberOfRuns]);

  const animateColor = () => {
    setNumberOfRuns(numberOfRuns + 1);
    document
      .getElementById("container")
      ?.animate({ backgroundColor: pickColor() }, 3000);
  };

  const colors = [
    "#EBF4FA",
    "#C0C0C0",
    "#E7E4D3",
    "#F1EFE2",
    "#C5AE87",
    "#BBD9EE",
    "#52ADDA",
    "#68B8DF",
    "#DBDBDB",
    "#AACD4B",
    "#AACD4B",
  ];
  let curcolor = 0;

  const pickColor = () => {
    var rand = Math.floor(Math.random() * 11);
    if (rand === curcolor) {
      pickColor();
    } else {
      curcolor = rand;
    }
    return colors[rand];
  };

  return (
    <div id="container" className={styles.container}>
      <div className={styles.innerContainer}>
        <span className={styles.heading}>Jon Meyer</span>
        <span className={styles.sub}>Software Engineer</span>
        <div className={styles.images}>
          <span className={styles.linkedIn}>
            <a
              className={styles.link}
              href="https://www.linkedin.com/in/jon-meyer-ba61b4103/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={LinkedInImage} alt="linkedIn logo" />
            </a>
          </span>
          <span className={styles.email}>
            <a
              className={styles.link}
              href="mailto:hello@jonm.com.au"
              target="_blank"
              rel="noreferrer"
            >
              <img src={EmailImage} alt="email logo" />
            </a>
          </span>
        </div>
      </div>
    </div>
  );
}

export default App;
